import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "grupos.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <>
      <Img
        imgStyle={{
          width: `100%`,
          height: `100%`,
          objectFit: `cover`,
          position: `absolute`,
          maxWidth: `100%`,
        }}
        fluid={data.file.childImageSharp.fluid}
        alt=""
      />
    </>
  )
}
