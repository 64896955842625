import React from "react"
//import Slider from "../components/sliderhome"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Cover from "../components/home/cover"
import Muebles from "../components/home/muebles"
import Grupos from "../components/home/grupos"
import Transporte from "../components/home/transporte"
import Arcades from "../components/home/arcades"

import { useStaticQuery, graphql, Link } from "gatsby"

const IndexPage = () => (
  <Layout>
    <SEO title="Inicio" description="Sitio Web Cerezo Rosa" />
    <section className="jumbotron breadcumb">
      <div className="mainbreadcumb">
        <div className="row m-10-hor page-title">
          <div className="col-md-12">
            <h4>Servicios Audiovisuales</h4>
            <h1>Alquiler de Material de Producción</h1>
          </div>
        </div>
      </div>
      <div id="cover">
        <Cover />
      </div>
    </section>

    {/* Claim */}
    <section>
      <div className="row m-10-hor">
        <div className="col-12 text-center">
          <div className="subheading">&iexcl;Rueda en Rosa!</div>
          <h2 className="heading">
            Alquiler de material de producción y transporte
          </h2>
          <div className="row">
            <div className="col-md-8 mx-auto">
              <p className="content">
                Cerezo Rosa es una empresa de alquiler de material enfocada al
                mundo audiovisual: publicidad, series, películas y videoclips.
              </p>
              <p className="content">
                Los productos que ofrece Cerezo Rosa sirven para complementar
                los servicios que ya ofrecemos a través de Rolling Productions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* Muebles */}
    <section>
      <div className="row m-10-hor">
        <div className="col-md-6 centered mb-5">
          <div>
            <h4 className="subheading">Muebles</h4>
            <h2 className="heading">Mobiliario de decoración</h2>
            <p>
              Alquiler Muebles de TV y muebles auxilares, de líneas sencillas y
              contemporáneas, de calidad. Productos que incluso se pueden
              adquirir y encargar para su venta.
            </p>
            <Link className="arrow" to="/muebles/">
              Saber Más
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <Muebles />
        </div>
      </div>
    </section>

    {/* Grupos */}
    <section>
      <div className="row m-10-hor">
        <div className="col-md-6 mb-5">
          <Grupos />
        </div>
        <div className="col-md-6 centered">
          <div>
            <h4 className="subheading">Grupos</h4>
            <h2 className="heading">Alquiler de Generadores</h2>
            <p>
              Alquiler de grupos de 3 kVA y 7 kVA. Robustos, silenciosos y
              fiables, perfectos para producciones.
            </p>
            <Link className="arrow" to="/grupos/">
              Saber Más
            </Link>
          </div>
        </div>
      </div>
    </section>

    {/* Transporte */}
    <section>
      <div className="row m-10-hor">
        <div className="col-md-6 centered mb-5">
          <div>
            <div className="subheading">Transporte</div>
            <div className="heading">Alquiler de Vehículos de Carga</div>
            <p>
              Furgonetas versátiles para terrenos difíciles, vehículos de avance
              de material, pequeños rodajes. Fáciles de manejar y aparcar.
            </p>
            <Link className="arrow" to="/transporte/">
              Saber Más
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <Transporte />
        </div>
      </div>
    </section>

    {/* Arcades */}
    <section>
      <div className="row m-10-hor">
        <div className="col-md-6 mb-5">
          <Arcades />
        </div>
        <div className="col-md-6 centered">
          <div>
            <h4 className="subheading">Arcades</h4>
            <h2 className="heading">Máquinas de Juego</h2>
            <p>
              Completa tu zona VIP o crea tu espacio de relax. Un rodaje
              conlleva muchas horas, tus clientes agradecerán los pequeños
              recesos. Juegos para liberar tensiones.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
